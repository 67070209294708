// SvgIcons.js
import React from "react";
export const HomeIc = ({color}) => (
  <svg
    width="31"
    height="29"
    color="black"
    viewBox="0 0 31 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 8.79919L15.1378 1.98663C15.3658 1.8684 15.6342 1.86838 15.8622 1.98663L29 8.79919"
      stroke={color}
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26.3 12.999V24.1997C26.3 25.7462 25.0912 26.9998 23.6 26.9998H7.39995C5.90878 26.9998 4.69995 25.7462 4.69995 24.1997V12.999"
      stroke={color}
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const PortfolioIc = ({color}) => (
  <svg
    width="30"
    height="28"
    viewBox="0 0 30 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.3333 4.94118C23.3333 2.21225 21.0948 0 18.3333 0H11.6667C8.90525 0 6.66667 2.21225 6.66667 4.94118H5C2.23858 4.94118 0 7.15342 0 9.88235V23.0588C0 25.7878 2.23858 28 5 28H25C27.7615 28 30 25.7878 30 23.0588V9.88235C30 7.15342 27.7615 4.94118 25 4.94118H23.3333ZM18.3333 3.29412H11.6667C10.7462 3.29412 10 4.03154 10 4.94118H20C20 4.03154 19.2538 3.29412 18.3333 3.29412ZM5 8.23529H25C25.9205 8.23529 26.6667 8.97272 26.6667 9.88235V23.0588C26.6667 23.9685 25.9205 24.7059 25 24.7059H5C4.07953 24.7059 3.33333 23.9685 3.33333 23.0588V9.88235C3.33333 8.97272 4.07953 8.23529 5 8.23529Z"
      fill={color}
    />
  </svg>
);
export const PricesIc = ({color}) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.5 1.88372C7.08451 1.88372 1.88372 7.08451 1.88372 13.5C1.88372 15.36 2.32015 17.1152 3.09533 18.6716C3.40645 19.2962 3.52625 20.0432 3.32995 20.7768L2.58198 23.5723C2.44461 24.0856 2.91432 24.5554 3.42773 24.418L6.22322 23.6701C6.95687 23.4738 7.7038 23.5936 8.32844 23.9047C9.88478 24.6799 11.64 25.1163 13.5 25.1163C19.9155 25.1163 25.1163 19.9155 25.1163 13.5C25.1163 7.08451 19.9155 1.88372 13.5 1.88372ZM0 13.5C0 6.04416 6.04416 0 13.5 0C20.9559 0 27 6.04416 27 13.5C27 20.9559 20.9559 27 13.5 27C11.3421 27 9.29996 26.493 7.48861 25.5909C7.21831 25.4562 6.94232 25.4276 6.71011 25.4898L3.91462 26.2377C2.00098 26.7497 0.250246 24.999 0.762279 23.0854L1.51024 20.2899C1.57238 20.0577 1.54381 19.7817 1.40919 19.5115C0.506985 17.7001 0 15.6579 0 13.5ZM13.5 7.53488C14.0202 7.53488 14.4419 7.95657 14.4419 8.47674V8.48959C15.8092 8.83407 16.9535 9.91214 16.9535 11.4069C16.9535 11.9271 16.5318 12.3488 16.0116 12.3488C15.4915 12.3488 15.0698 11.9271 15.0698 11.4069C15.0698 10.9247 14.535 10.2558 13.5 10.2558C12.465 10.2558 11.9302 10.9247 11.9302 11.4069C11.9302 11.8893 12.465 12.5581 13.5 12.5581C15.2392 12.5581 16.9535 13.7635 16.9535 15.5931C16.9535 17.0879 15.8092 18.166 14.4419 18.5104V18.5233C14.4419 19.0434 14.0202 19.4651 13.5 19.4651C12.9798 19.4651 12.5581 19.0434 12.5581 18.5233V18.5104C11.1908 18.166 10.0465 17.0879 10.0465 15.5931C10.0465 15.0729 10.4682 14.6512 10.9884 14.6512C11.5085 14.6512 11.9302 15.0729 11.9302 15.5931C11.9302 16.0753 12.465 16.7441 13.5 16.7441C14.535 16.7441 15.0698 16.0753 15.0698 15.5931C15.0698 15.1107 14.535 14.4419 13.5 14.4419C11.7608 14.4419 10.0465 13.2367 10.0465 11.4069C10.0465 9.91214 11.1908 8.83407 12.5581 8.48959V8.47674C12.5581 7.95657 12.9798 7.53488 13.5 7.53488Z"
      fill={color}
    />
  </svg>
);

// Add more SVG components as needed
