import "./App.css";
import logo from "./Media/logo.png";
import caretDown from "./Media/caret_down.svg";
import React, { useEffect, useRef, useState } from "react";
import Carousel from "./Carousel";
import mobile from "./Media/mobile.svg";
import website from "./Media/website.svg";
import link from "./Media/link.svg";
import threed from "./Media/threed.svg";
import instagram from "./Media/instagram.svg";
import youtube from "./Media/youtube.svg";
import telegram from "./Media/telegram.svg";
import eentr from "./Media/eentr.png";
import { HomeIc, PortfolioIc, PricesIc } from "./Icons";
import Model3D from "./Model3D";
import lang_uz from "./lang_uz.json";
import lang_ru from "./lang_ru.json";
import lang_en from "./lang_en.json";
import {
  Link,
  RouterProvider,
  createBrowserRouter,
  useParams,
} from "react-router-dom";
const sections = ["main", "portfolio", "prices"];
const App = () => {
  const texts = [];
  const langs = { uz: "O'z", ru: "Ру", en: "En" };
  const [isLangChange, setIsLangChange] = useState(false);
  const icons = [website, mobile, threed];
  const [currentSection, setCurrentSection] = useState("");
  const sectionRefs = useRef({});
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);
  const queryLang = new URLSearchParams(window.location.search).get("lang");

  const [currentLang, setCurrentLang] = useState("");

  useEffect(() => {
    if (queryLang === "uz") {
      setCurrentLang(lang_uz);
    } else if (queryLang === "ru") {
      setCurrentLang(lang_ru);
    } else if (queryLang === "en") {
      setCurrentLang(lang_en);
    } else {
      setCurrentLang(lang_uz);
    }
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };
    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setCurrentSection(entry.target.id);
        }
      });
    };
    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );
    sections.forEach((section) => {
      if (sectionRefs.current[section]) {
        observer.observe(sectionRefs.current[section]);
      }
    });
    window.addEventListener("resize", handleResize);
    return () => {
      observer.disconnect();
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleLangChangeBlur = () => {
    setTimeout(() => setIsLangChange(false), 200)
  }
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  let { lang } = useParams();
  texts.push(currentLang.saytlar, currentLang.mobil_ilovalar, currentLang.dizayn3d)
  return (
    
    <div className="App">
      <meta name="uniwebuz" content="uniweb.uz uniwebuz" />
      <div className="nav">
        <div className="nav-item" onClick={() => scrollToSection("main")}>
          <HomeIc color={currentSection === "main" ? "#64D62F" : "white"} />
        </div>
        <div className="nav-item" onClick={() => scrollToSection("portfolio")}>
          <PortfolioIc
            color={currentSection === "portfolio" ? "#64D62F" : "white"}
          />
        </div>
        <div className="nav-item" onClick={() => scrollToSection("prices")}>
          <PricesIc color={currentSection === "prices" ? "#64D62F" : "white"} />
        </div>
      </div>
      <div className="navbar">
        <div className="navbar_container">
          <div className="navbar_item" style={{cursor:'pointer'}}
          onClick={() => scrollToSection("main")}
          >
            <img src={logo} className="navbar_item-logo" alt="" />
          </div>
          {isDesktop && (
            <div className="navbar_item font-s fz-s navbar-desktop">
              <div
                className="navb-item"
                onClick={() => scrollToSection("main")}
                style={{
                  color: currentSection === "main" ? "#a3df18" : "white",cursor:'pointer'
                }}
              >
                {currentLang.bosh_sahifa}
              </div>
              <div className="separator"></div>
              <div
                className="navb-item"
                onClick={() => scrollToSection("portfolio")}
                style={{
                  color: currentSection === "portfolio" ? "#a3df18" : "white",cursor:'pointer'
                }}
              >
                {currentLang.loyihalarimiz}
              </div>
              <div className="separator"></div>
              <div
                className="navb-item"
                onClick={() => scrollToSection("prices")}
                style={{
                  color: currentSection === "prices" ? "#a3df18" : "white",cursor:'pointer'
                }}
              >
                {currentLang.narxlar}
              </div>
            </div>
          )}
          <div
            className="navbar_item font-s fz-m" 
            tabIndex={0}
            onFocus={() => setIsLangChange(true)}
            onBlur={() => handleLangChangeBlur()}
            style={{cursor:'pointer'}}
          >
            <div className="navbar_item-lang font-s fz-s" >{langs[currentLang.lang]}</div>
            <img src={caretDown} className="navbar_item-caret" alt="" />
            <div
              className="lang-list font-s fz-s flex-column"
              style={{ gap: "15px",display: isLangChange?'flex':'none'}}
            >
              {Object.entries(langs).map(([key, value]) => (
                <div key={key}>
                  <a
                    href={"/?lang=" + key}
                    className=""
                    style={{
                      color: currentLang.lang === key ? "#a3df18" : "white",
                    }}
                  >
                    {value}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div
          className="main"
          id="main"
          ref={(el) => (sectionRefs.current["main"] = el)}
        >
          <div className="main_cont">
            <div className="main_item">
              {!isDesktop && <Carousel texts={texts} icons={icons} />}
              {isDesktop && (
                <div className="font-f fz-m fw-l desk-icons">
                  <div className="desk-icon">
                    <img src={icons[0]} style={{width:'20px'}} alt="" />
                    {texts[0]}
                  </div>
                  <div className="desk-icon">
                    <img src={icons[1]} alt="" />
                    {texts[1]}
                  </div>
                  <div className="desk-icon">
                    <img src={icons[2]} alt="" />
                    {texts[2]}
                  </div>
                </div>
              )}
            </div>
            <div className="main_item font-f fw-b flex-center title">
              UNIWEBUZ
            </div>
            {!isDesktop && (
              <div className="main_item flex-center model-mobile">
                <Model3D scale={0.72} canvas={"40vh"} width={'55%'} zindex={-0.55}/>
              </div>
            )}
            <div className="main_item font-f fz-mb fw-l flex-center description">
              {currentLang.lang === "uz" && (
                <div className="text">
                  <span className="highlited"> Oldindan to’lovsiz </span>
                  noyob loyihalar.
                  <br />
                  Hozir buyurtma bering, to’lovni esa keyin qiling!
                </div>
              )}
              {currentLang.lang === "ru" && (
                <div className="text">
                  Оригинальные проекты
                  <span className="highlited" > без предоплат. </span>
                  <br />
                  Закажите сейчас, оплатите потом!
                </div>
              )}
              {currentLang.lang === "en" && (
                <div className="text">
                  Unique projects <br></br>
                  <span className="highlited" > without prepayments. </span>
                  
                  Order now, pay later!
                </div>
              )}
            </div>

            <a
              href="https://t.me/uniwebuz"
              className="main_item flex-center font-s fz-m"
            >
              <div className="order flex-center fw-l" style={{letterSpacing:'0px'}}>{currentLang.buyurtma_berish}</div>
            </a>
            <div className="main_item font-f fz-m fw-l flex-center contact">
              
            </div>
            <div className="main_item socials">
              <img src={instagram} alt="" />
              <img src={youtube} alt="" />
              <img src={telegram} alt="" />
            </div>
          </div>
          {isDesktop && (
            <div className="main_item flex-center model-mobile ">
              <Model3D scale={0.5} canvas={"85vh"} width={'100%'} zindex={0}/>
            </div>
          )}
        </div>
        <div
          className="portfolio"
          id="portfolio"
          ref={(el) => (sectionRefs.current["portfolio"] = el)}
        >
          <div className="portfolio_item font-f fz-b flex-column">
            <div className="">{currentLang.loyihalarimiz}</div>
            <div className="underline"></div>
          </div>
          <div className="portfolio_item project flex-center flex-column">
            <div className="project_link font-s fz-m">
              www.eentr.uz
              <img src={link} alt="" />
            </div>
            <img src={eentr} alt="" />
            <div className="fz-m fw-l font-f desc">
              {currentLang.zamonaviy}
            </div>
          </div>
        </div>
        <div
          className="prices font-f fz-mb"
          id="prices"
          ref={(el) => (sectionRefs.current["prices"] = el)}
        >
          <div className="font-f fz-b flex-column">
            <div className="">{currentLang.narxlar}</div>
            <div className="underline"></div>
          </div>
          <div className="prices-desk font-f">
            <div className="prices_item flex-column">
              <div >{currentLang.saytlar}</div>
              <img src={website} style={{ width: "37%" }} />
              <div className="price_tag flex-column">
                <div className="price-old">{currentLang.eski_narx_sayt}</div>
                <div className="price-new">{currentLang.yangi_narx_sayt}</div>
              </div>
            </div>
            <div className="prices_item flex-column">
              <div className="">{currentLang.mobil_ilovalar}</div>
              <img src={mobile} style={{ width: "25%" }} />
              <div className="price_tag flex-column">
              <div className="price-old">{currentLang.eski_narx_mobil}</div>
                <div className="price-new">{currentLang.yangi_narx_mobil}</div>
              </div>
            </div>
            <div className="prices_item flex-column">
              <div className="">{currentLang.dizayn3d}</div>
              <img src={threed} style={{ width: "30%", marginRight: "3px" }} />
              <div className="price_tag flex-column">
              <div className="price-old">{currentLang.eski_narx_dizayn}</div>
                <div className="price-new">{currentLang.yangi_narx_dizayn}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
