import React, { useState, useEffect } from 'react';
import './Carousel.css';
const Carousel = ({ texts = [],icons = [] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (texts.length === 0) return;

    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % texts.length);
    }, 3000); // Change text every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [texts]);

  if (texts.length === 0) {
    return <div className="carousel-container">No texts available</div>;
  }

  return (
    <div className="carousel-container">
      <div className="carousel-text slide-in font-f fz-m fw-l" key={currentIndex}>
        <img src={icons[currentIndex]} alt="" />
        {texts[currentIndex]}
      </div>
    </div>
  );
};

export default Carousel;

