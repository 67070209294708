// src/My3DScene.jsx
import React, { useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';
import Scene from "./Media/iphone_15_pro_max.glb"
const Model = (props) => {
    const { scene } = useGLTF(Scene);
    const modelRef = useRef();
    let time = useRef(0);
    useFrame((state, delta) => {
        if (modelRef.current) {
          time.current += delta; // increment time with the delta time
          modelRef.current.rotation.y = Math.sin(time.current) * 0.2; // oscillate rotation.y
        }
      });
  
    return <primitive object={scene} ref={modelRef} {...props} />;
  };

  const Model3D = ({scale, canvas, width, zindex}) => {
    return (
        <Canvas className='model' style={{ height: canvas,width:width,marginTop:'30px'}} shadows camera={{ position: [0, 2, 5], fov: 30 }}>
        {/* Brighter ambient light */}
        <ambientLight intensity={0.6} />
        
        {/* Brighter point light with shadows */}
        <pointLight castShadow intensity={1.2} position={[10, 10, 10]} />
        
        {/* Additional directional light for better illumination */}
        <directionalLight
          castShadow
          intensity={0.8}
          position={[-5, 10, 5]}
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-camera-near={0.1}
          shadow-camera-far={50}
        />
        
        {/* Example box model */}
        
        {/* GLTF model */}
        <Model position={[0, zindex, 0]} scale={[scale, scale, scale]} style={{marginTop:'50px'}}/>
        
        <OrbitControls enableZoom={false} enablePan={false} />
      </Canvas>
    );
  };
  
export default Model3D;
